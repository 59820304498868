import React, { useState } from "react"
import Modal from 'react-modal';
import { request } from "../../api/request";
import Loading from "../utilities/Loading";

export default function CloseCase(props) {

    Modal.setAppElement('#root');

    let [data, setData] = useState({
        loading: false,
        note: ''
    })

    const submit = async () => {
        if (!data.note) { alert("Please enter description"); return; }
        setData({ ...data, loading: true })
        const payload = {
            "agent": props.agent,
            "case": props.case,
            "note": data.note
        }
        const response = await request(`cases/${props.case}/close-case/`, payload, "POST")
        if (response.status === 200) {
            setData({ ...data, loading: false, note: '' })
            props.onClose()
        } else {
            setData({ ...data, loading: false })
        }
    }

    return (
        <Modal
            contentLabel="Case Close Modal"
            isOpen={props.isOpen}
            // onAfterOpen={}
            onRequestClose={props.onClose}
            style={{
                overlay: {
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)'
                },
                content: {
                    position: 'absolute',
                    top: '5em', left: '30%',
                    height: '300px',
                    width: '400px',
                    backgroundColor: '#fff',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }
            }}
        >
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        {
                            !data.loading && <form className='form' onSubmit={e => { e.preventDefault(); submit(); }}>
                                <div className='row'>
                                    <div className='form-group col-12'>
                                        <label htmlFor='note' style={{ textAlign: 'center !important', marginBottom: "1.5rem" }}>Reason for closure</label>
                                        <textarea type={'text'} className='form-control' name='note' id='note'
                                            value={data.note} rows={4} autoFocus
                                            onChange={e => setData({ ...data, note: e.target.value })} />
                                    </div>
                                </div>
                                <div className='row justify-content-end mt-3'>
                                    <div className='col-md-6 col-sm-10 offset-md 0 offset-sm-1'>
                                        <input type={'submit'} className='form-control btn-success'
                                            id='submit' value={"Close Case"} disabled={data.loading} />
                                    </div>
                                </div>
                            </form>
                        }
                        {
                            data.loading && <Loading height={"200px"} />
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
};