import { Pagination } from '@mui/material';
import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation } from 'react-router-dom';
import { APP_TITLE, getStaff } from '../../api/env';
import { request } from '../../api/request';
import Loading from '../utilities/Loading';
import ReportFilter from './reports';


export default function Cases(props) {
    document.title = `${APP_TITLE}Cases`
    const _location = useLocation()
    let _search = new URLSearchParams(_location.search);
    _search = _search.get('status')
    let [data, setData] = useState({
        loading: true,
        cases: [],
        user: props.user,
        startDate: null,
        endDate: null,
        search: _search,
        filterModel: false,
        pageCount: 0,
        page: 1,
        itemsPerPage: 50
    })

    const getCases = async (page, itemsPerPage) => {
        if (page === data.page) return;
        let url = `cases/?limit=${itemsPerPage ? itemsPerPage : data.itemsPerPage}&page=${page ? page : data.page}`
        if (data.search) url += `&status=${data.search}`
        if ([2, 4].includes(data.user.designation)) {
            if (url.includes('?')) url += `&uId=${data.user.uid}`
        }
        const response = await request(url)
        if (response.status === 200) {
            setData({
                ...data, loading: false,
                cases: response.payload.results,
                pageCount: Math.ceil(response.payload.count / data.itemsPerPage),
                page,
            })
        }
        else setData({ ...data, loading: false })
    }

    useEffect(() => {
        getCases()
    }, [])

    return (
        <div className='row'>
            {
                data.loading && <Loading />
            }
            {
                !data.loading && <React.Fragment>
                    <div className='col-12 mb-4'>
                        <div className='row justify-content-between'>
                            <div className='col-md-5 col-sm-10 md-offset-0 sm-offset-1'>
                                <h2>Customer cases</h2>
                            </div>
                            <div className='col-md-4 col-sm-10 md-offset-0 sm-offset-1'>
                                <div className='div-btn'
                                    onClick={() => setData({ ...data, filterModel: true })}>Export data</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 table-responsive'>
                        <table className='table table-hover table-striped'>
                            <thead >
                                <tr>
                                    <th>Date</th>
                                    <th>Case#</th>
                                    <th>Case type</th>
                                    <th>Customer Name</th>
                                    <th>Phone Number</th>
                                    <th>DStv Agent</th>
                                    <th>Case Status</th>
                                    <th>Location</th>
                                    {
                                        data.search === 'sc' && <th>Scheduled date</th>
                                    }
                                    {
                                        data.search === 'nc' && <th></th>
                                    }
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    data.cases.length > 0 && data.cases.map((_case, _index) => {
                                        return (
                                            <tr key={_case.uid}>
                                                <td>
                                                    <Link to={`/cases/${_case.uid}`} className={`td-link`}>
                                                        {_case.created_at}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={`/cases/${_case.uid}`} className={`td-link`}>
                                                        {_case.case_number}
                                                    </Link>
                                                </td>
                                                <td style={{ textTransform: 'capitalize' }}>{_case.case_type ? _case.case_type : 'new decoder'}</td>
                                                <td>{_case.customer_name}</td>
                                                <td>{_case.phone_number}</td>
                                                <td>{_case.created_by}</td>
                                                <td>{_case.case_status}</td>
                                                <td>{`${_case.region} | ${_case.physical_address}`}</td>
                                                {
                                                    data.search === 'sc' && <td>{_case.schedule_date}</td>
                                                }
                                                {
                                                    (!_case.assigned && data.search === 'nc') &&
                                                    <td>
                                                        <button type="button" className="btn btn-success"
                                                            onClick={async () => {
                                                                const response = await request(`cases/${_case.uid}/assign-case/?uId=${data.user.uid}`)
                                                                if (response.status === 200) {
                                                                    alert(`Case number ${_case.case_number} is assigned successfully!`)
                                                                    await getCases();
                                                                }

                                                            }}
                                                        >Assign</button></td>
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    data.cases.length == 0 &&
                                    <tr>
                                        <td colSpan={7} style={{ textAlign: 'center' }}>
                                            No Cases yet
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            }
            {
                data.cases.length > 0 && <Pagination
                    count={data.pageCount}
                    color="secondary"
                    shape='rounded'
                    size='large'
                    variant='outlined'
                    boundaryCount={2}
                    onChange={(event, page) => {
                        getCases(page, data.itemsPerPage)
                    }}
                />
            }
            <ReportFilter
                isOpen={data.filterModel}
                onClose={() => setData({ ...data, filterModel: false })}
            />
        </div>
    )
}