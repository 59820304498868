import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { APP_TITLE, DESIGNATIONS } from '../../api/env';
import { request } from '../../api/request';
import Loading from '../utilities/Loading';

export default function Search() {
    const _location = useLocation()
    let _search = new URLSearchParams(_location.search);
    _search = _search.get('q')
    document.title = `${APP_TITLE}Search results`

    let [data, setData] = useState({
        loading: true,
        results: { cases: [], staff: [], installers: [] }
    })

    const getResults = async () => {
        const response = await request(`search/?q=${_search}`)
        if (response.status === 200) {
            // console.log(response.payload.result)
            setData({
                ...data,
                loading: false,
                results: response.payload.result
            })
        } else {
            setData({ ...data, loading: false })
        }
    }

    useEffect(() => {
        getResults()
    }, [])

    return (
        <div className='row'>
            {
                data.loading && <Loading />
            }
            {
                !data.loading && <div className='col-12'>
                    <div className='row'>
                        <div className='col-12 page-header mb-4'>
                            <h2>Search results</h2>
                        </div>
                        {
                            data.results.cases.length > 0 && data.results.cases.map((_case, index) => {
                                return (
                                    <Link className='search-result-link col-10' to={`/cases/${_case.uid}`}
                                        key={`cases${index}`}>
                                        Case number {_case.case_number}<br />
                                        <span style={{ color: "#000" }}>{_case.customer_name} - {_case.phone_number}</span>
                                    </Link>
                                )
                            })
                        }
                        {
                            data.results.staff.length > 0 && data.results.staff.map((_staff, index) => {
                                return (
                                    <Link className='search-result-link col-10' to={`/agents/${_staff.uid}`}
                                        key={`staff${index}`}>
                                        {_staff.first_name} {_staff.last_name} <br />
                                        <span style={{ color: "#000" }}>{DESIGNATIONS[_staff.designation]}</span>
                                    </Link>
                                )
                            })
                        }
                    </div>

                </div>
            }
        </div>
    )
}