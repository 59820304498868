import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { APP_TITLE, getStaff } from '../api/env';
import { request } from '../api/request';
import Loading from './utilities/Loading';

export default function Dashboard(props) {
    document.title = `${APP_TITLE}Home`

    let [data, setData] = useState({
        loading: true,
        statistics: {},
        user: props.user
    })

    const getData = async () => {
        let url = 'case-statistics/'
        if ([2, 4].includes(data.user?.designation)) url += `?uId=${data.user?.uid}`
        const response = await request(url)
        if (response.status === 200) setData({ ...data, loading: false, statistics: response.payload })
        else setData({ ...data, loading: false })
    }

    useEffect(() => {
        getData()
    }, [])

    if (data.loading) return <Loading />
    else return (
        <div className='row'>
            {
                data.user.groups.includes(1) && <Link to={'/cases?status=nc'} className="col-md-6 mb-4 td-link">
                    <div className="card shadow h-100 py-2" style={{ backgroundColor: "#337AB7", color: "#FFF" }}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-uppercase mb-1">
                                        New customers from call center</div>
                                    <div className="h5 mb-0 font-weight-bold">
                                        {data.statistics.new_customers ? data.statistics.new_customers : 0}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-users fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            }
            <Link to={'/cases?status=pn'} className="col-md-6 mb-4 td-link">
                <div className="card shadow h-100 py-2" style={{ backgroundColor: "#337AB7", color: "#FFF" }}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-uppercase mb-1">
                                    New Customer Cases</div>
                                <div className="h5 mb-0 font-weight-bold ">
                                    {data.statistics.pending ? data.statistics.pending : 0}
                                </div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-users fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>

            <Link to={'/cases?status=cl'} className="col-md-6 mb-4 td-link">
                <div className="card shadow h-100 py-2" style={{ backgroundColor: "#5CB85C", color: "#FFF" }}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-uppercase mb-1">
                                    Closed Cases</div>
                                <div className="text-xs font-weight-bold text-uppercase mb-1">
                                    {data.statistics.closed_cases ? data.statistics.closed_cases : 0}
                                </div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-list fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>

            <Link to={'/cases?status=inp'} className="col-md-6 mb-4 td-link">
                <div className="card shadow h-100 py-2" style={{ backgroundColor: "#F0AD4E", color: "#FFF" }}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-uppercase mb-1">
                                    Cases in progress</div>
                                <div className="h5 mb-0 font-weight-bold ">
                                    {data.statistics.customers_in_progress ? data.statistics.customers_in_progress : 0}
                                </div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-list fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>

            <Link to={'/cases?status=sc'} className="col-md-6 mb-4 td-link">
                <div className="card shadow h-100 py-2" style={{ backgroundColor: "#87CEFA", color: "#FFF" }}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-uppercase mb-1">
                                    Scheduled Customer Cases</div>
                                <div className="h5 mb-0 font-weight-bold ">
                                    {data.statistics.scheduled_cases ? data.statistics.scheduled_cases : 0}
                                </div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-list fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>

            <Link to={'/cases?status=na'} className="col-md-6 mb-4 td-link">
                <div className="card shadow h-100 py-2" style={{ backgroundColor: "#F78774", color: "#FFF" }}>
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-xs font-weight-bold text-uppercase mb-1">
                                    Cases with no response from customers</div>
                                <div className="h5 mb-0 font-weight-bold ">
                                    {data.statistics.customers_not_available ? data.statistics.customers_not_available : 0}
                                </div>
                            </div>
                            <div className="col-auto">
                                <i className="fas fa-list fa-2x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}