import React, { useState } from "react"
import Modal from 'react-modal';
import { request } from "../../api/request";
import Loading from "../utilities/Loading";

export default function EditPassword(props) {

    Modal.setAppElement('#root');

    let [data, setData] = useState({
        loading: false,
        password1: '',
        password2: '',
        errorMessage: null
    })

    return (
        <Modal
            contentLabel="Verification Modal"
            isOpen={props.isOpen}
            // onAfterOpen={}
            onRequestClose={props.onClose}
            shouldCloseOnOverlayClick={false}
            style={{
                overlay: {
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)'
                },
                content: {
                    position: 'absolute',
                    top: '5em', left: '30%',
                    height: '400px',
                    width: '400px',
                    backgroundColor: '#fff',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }
            }}
        >
            <div className='container'>
                <div className='row'>
                    {
                        !data.loading && <div className='col-12'>
                            <form className='form'
                                onSubmit={async e => {
                                    e.preventDefault();
                                    if (data.password1 !== data.password2) {
                                        setData({ ...data, errorMessage: 'Passwords should match' })
                                        return;
                                    } else {
                                        setData({ ...data, loading: true })
                                        const payload = {
                                            "agent": props.agent,
                                            "password": data.password1
                                        }
                                        const response = await request(`staff/${props.agent}/change-pwd/`, payload, "POST")
                                        if (response.status === 200) {
                                            props.onClose()
                                            alert("Your password was updated successfully")
                                        }
                                        setData({ ...data, loading: false, password1: '', password2: '' });
                                    }
                                }}
                            >
                                <div className='row'>
                                    <div className='form-group col-10 offset-1'>
                                        <label htmlFor='password1'>New Password</label>
                                        <input type={'password'} className='form-control' name='password1' id='password1'
                                            value={data.password1} required={true}
                                            onChange={e => {
                                                setData({ ...data, password1: e.target.value, errorMessage: null })
                                            }} />
                                    </div>
                                    <div className='form-group col-10 offset-1'>
                                        <label htmlFor='password2'>Confirm Password</label>
                                        <input type={'password'} className='form-control' name='password2' id='password2'
                                            value={data.password2} required={true}
                                            onChange={e => {
                                                setData({ ...data, password2: e.target.value, errorMessage: null })
                                            }} />
                                    </div>
                                    <div className='col-10 offset-1 mt-3'>
                                        <input type={'submit'} className='form-control btn-success'
                                            id='submit' value={"Submit"} disabled={data.loading} />
                                    </div>
                                    <div className='col-10 offset-1 mt-4'>
                                        <div className="div-btn"
                                            onClick={() => {
                                                props.onClose()
                                            }}>Cancel</div>
                                    </div>
                                    <div className='col-10 offset-1 mt-3' style={{ color: 'red', textAlign: 'center' }}>
                                        {
                                            data.errorMessage && data.errorMessage
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                    {
                        data.loading && <Loading height={420} />
                    }
                </div>
            </div>
        </Modal>
    );
};