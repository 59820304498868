import React, { useEffect, useState } from "react";
import {
	BrowserRouter as Router,
	Route,
	NavLink,
	Switch,
	Link,
	useHistory,
} from "react-router-dom";
import Http404 from "./404";

import Loading from "../utilities/Loading";
import Login from "../auth/login";
import Dashboard from "../dashboard";
import Agents from "../user/users";
import NewAgent from "../user/newUser";
import { getStaff, setStaff } from "../../api/env";
import NewCase from "../cases/newCase";
import Cases from "../cases/cases";
import Customers from "../customers/customers";
import CaseDetails from "../cases/details";
import Installers from "../installers/list";
import NewInstaller from "../installers/new-installer";
import AgentDetails from "../user/edit";
import Search from "../search/search-results";

function Navigation(props) {
	const [search, setSearch] = useState('')
	// const _history = useHistory()
	return (
		<div id="wrapper">
			<Router>
				<ul className="navbar-nav sidebar sidebar-dark" id="accordionSidebar"
					style={{
						height: '100vh',
						backgroundColor: "rgba(30,30,30,0.9)"
					}}
				>
					<a className="sidebar-brand d-flex align-items-center justify-content-center"
						href="/"
						style={{
							width: "100%",
							backgroundColor: '#93278F',
							borderRight: "1px solid rgba(255,255,255,0.5)",
							color: "#FFF"
						}}>
						<div className="sidebar-brand-text " style={{
							fontSize: '24px',
							textAlign: 'center'
						}}>
							STEP MARK
						</div>
					</a>

					<hr className="sidebar-divider my-0" />

					<li className="nav-item active">
						<NavLink className="nav-link" to={'/'}>
							<i className="fas fa-fw fa-tachometer-alt"></i>
							<span>&nbsp; Dashboard</span></NavLink>
					</li>

					<hr className="sidebar-divider" />

					<li className="nav-item">
						<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#customersMenu"
							aria-expanded="true" aria-controls="customersMenu">
							<i className="fas fa-fw fa-users"></i>
							<span>&nbsp; Customers</span>
						</a>
						<div id="customersMenu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
							<div className="bg-white py-2 collapse-inner rounded">
								<NavLink className="collapse-item" to={'/customers'} exact={true}>All Customers</NavLink>
							</div>
						</div>
					</li>

					<hr className="sidebar-divider" />

					<li className="nav-item">
						<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#caseMenu"
							aria-expanded="true" aria-controls="caseMenu">
							<i className="fas fa-fw fa-list"></i>
							<span>&nbsp;Cases</span>
						</a>
						<div id="caseMenu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
							<div className="bg-white py-2 collapse-inner rounded">
								<NavLink className="collapse-item" to={'/cases'} exact={true}>All Cases</NavLink>
								{
									[3, 4].includes(props.appUser.designation) &&
									<NavLink className="collapse-item" to={'/new-case'} exact={true}>New Case</NavLink>
								}
							</div>
						</div>
					</li>

					<hr className="sidebar-divider" />

					{
						[1, 3].includes(props.appUser.designation) && <li className="nav-item">
							<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#agentsMenu"
								aria-expanded="true" aria-controls="agentsMenu">
								<i className="fas fa-fw fa-users"></i>
								<span>&nbsp; Agents</span>
							</a>
							<div id="agentsMenu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
								<div className="bg-white py-2 collapse-inner rounded">
									<NavLink className="collapse-item" to={'/agents'} exact={true}>Agents</NavLink>
									<NavLink className="collapse-item" to={'/new-agent'} exact={true}>New Agent</NavLink>
								</div>
							</div>
						</li>
					}
					{
						[1].includes(props.appUser.designation) &&
						<li className="nav-item">
							<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#installerMenu"
								aria-expanded="true" aria-controls="installerMenu">
								<i className="fas fa-fw fa-users"></i>
								<span>&nbsp;Installers</span>
							</a>
							<div id="installerMenu" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
								<div className="bg-white py-2 collapse-inner rounded">
									<NavLink className="collapse-item" to={'/installers'} exact={true}>All Installers</NavLink>
									<NavLink className="collapse-item" to={'/new-installer'} exact={true}>New Installer</NavLink>
								</div>
							</div>
						</li>
					}
				</ul>

				<div id="content-wrapper" className="d-flex flex-column">

					<div id="content">

						<nav className="navbar navbar-expand navbar-light topbar mb-4 static-top shadow"
							style={{ backgroundColor: "#93278F" }}>

							<button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
								<i className="fa fa-bars"></i>
							</button>

							<form onSubmit={e => {
								e.preventDefault()
								if (!search || search === '') return
								else {
									window.location.href = `/search?q=${search}`
								}
							}}
								className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
								<div className="input-group">
									<input type="text" className="form-control bg-light border-0 small"
										placeholder="Search for..."
										value={search}
										onChange={e => setSearch(e.target.value)}
										aria-label="Search" aria-describedby="basic-addon2" />
									<div className="input-group-append">
										<button className="btn btn-primary" type="submit">
											<i className="fas fa-search fa-sm"></i>
										</button>
									</div>
								</div>
							</form>

							<ul className="navbar-nav ml-auto">
								<div className="topbar-divider d-none d-sm-block"></div>

								<li className="nav-item dropdown no-arrow">
									<a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
										data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<span className="mr-2 d-none d-lg-inline text-gray-600 small"></span>
										<img className="img-profile rounded-circle"
											src="img/undraw_profile.svg" />
									</a>
									<div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
										aria-labelledby="userDropdown">
										<Link className="dropdown-item" to={`/agents/${props.appUser.uid}`}>
											<i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
											{props.appUser ? `${props.appUser.first_name} ${props.appUser.last_name}` : "Profile"}
										</Link>
										<div className="dropdown-divider"></div>
										<a className="dropdown-item" href="#"
											data-toggle="modal"
											data-target="#logoutModal"
											onClick={(e) => {
												e.preventDefault();
												props.logout();
											}}
										>
											<i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
											Logout
										</a>
									</div>
								</li>
							</ul>
						</nav>
						<div className="container" style={{
							overflowY: 'auto',
							height: 'calc(100vh - 80px)',
							overflowX: 'hidden',
							paddingBottom: '20px',
							position: 'relative',
							top: "-23px",
							paddingTop: "10px",
							color: "#000000 !important"
						}}>
							<Switch>
								<Route path="/" children={<Dashboard user={props.appUser} />} exact />
								<Route path="/login" children={<Dashboard user={props.appUser} />} exact />
								<Route path="/search" children={<Search user={props.appUser} />} exact />
								<Route path="/customers" children={<Customers user={props.appUser} />} exact />
								<Route path="/cases" children={<Cases user={props.appUser} />} exact />
								<Route path="/cases/:caseId" children={<CaseDetails user={props.appUser} />} />
								<Route path="/new-case" children={<NewCase user={props.appUser} />} exact />
								<Route path="/agents" children={<Agents user={props.appUser} />} exact />
								<Route path="/agents/:agentId" children={<AgentDetails user={props.appUser} />} />
								<Route path="/new-agent" children={<NewAgent user={props.appUser} />} exact />
								<Route path="/installers" children={<Installers user={props.appUser} />} exact />
								<Route path="/new-installer" children={<NewInstaller user={props.appUser} />} exact />
								<Route path="" children={Http404} />
							</Switch>
						</div>
					</div>
				</div>
			</Router>
		</div>
	);
}
const staff = getStaff()

export default function Application() {
	let [user, setUser] = useState({ loading: true, details: null });

	useEffect(() => {
		if (staff) setUser({
			...user, details: staff, loading: false
		})
		else setUser({
			...user, loading: false
		})
	}, [])
	if (user.loading) {
		return <Loading />
	} else {
		if (!user.details) {
			return (
				<Login returnUser={u => setUser({ loading: false, details: u })} />
			)
		} else {
			return (
				<Navigation
					appUser={user.details}
					logout={() => {
						setUser({ ...user, loading: false, details: null })
						setStaff(null)
						sessionStorage.clear()
					}}
				/>
			);
		}
	}
}