import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_TITLE, getStaff } from '../../api/env';
import { request } from '../../api/request';
import Restricted from '../static/restricted';
import Loading from '../utilities/Loading';

let user = getStaff();

export default function NewAgent() {
    document.title = `${APP_TITLE}New Installer`

    let [data, setData] = useState({
        loading: true,
        name: '',
        phone_number: '',
        zone: '',
        region: '',
        physical_address: '',
        user,
        _zones: [],
        _regions: []
    })

    const _history = useHistory();

    const checkPermission = () => {
        const _permission = [1]
        if (data.user) {
            if (_permission.includes(data.user.designation)) return true;
        }
        return false
    }
    const getZones = async () => {
        const response = await request('zones/')
        const _perm = checkPermission()
        if (response.status === 200) setData({ ...data, loading: false, _zones: response.payload.results, permission: _perm })
        else setData({ ...data, loading: false, permission: _perm })
    }

    const getRegions = async (z) => {
        const response = await request(`regions/?zone=${z}`)
        if (response.status === 200) return response.payload.results
        else return []
    }

    const submitForm = async () => {
        setData({ ...data, loading: true })
        const payload = {
            name: data.name,
            phone_number: data.phone_number,
            zone: data.zone,
            region: data.region,
            physical_address: data.physical_address,
        }
        const response = await request('installers/', payload, 'POST')
        if (response.status === 201) {
            _history.push('installers/')
            setData({ ...data, loading: false })
        } else {
            setData({ ...data, loading: false })
        }
    }

    useEffect(() => {
        getZones();
    }, [])

    if (data.permission) {
        return (
            <>
                {
                    data.loading && <Loading />
                }
                {
                    !data.loading && <div className='row'>
                        <div className='col-10 page-header ml-3 mb-4'>
                            <h2>Add a new installer</h2>
                        </div>

                        <div className='col-12'>
                            <form className='form' onSubmit={e => { e.preventDefault(); submitForm(); }}>
                                <div className='row'>
                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='first_name'>Full Name</label>
                                        <input type={'text'} className='form-control' name='first_name' id='first_name'
                                            value={data.first_name} required
                                            onChange={e => setData({ ...data, name: e.target.value })} />
                                    </div>

                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='phone_number'>phone number</label>
                                        <input type={'text'} className='form-control' name='phone_number' id='phone_number'
                                            value={data.phone_number} required maxLength={10}
                                            onChange={e => setData({ ...data, phone_number: e.target.value })} />
                                    </div>

                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='zone'>zone</label>
                                        <select id='zone' name='zone' className='form-control'
                                            onChange={async e => {
                                                const _zone_ = e.target.value
                                                const _resp = await getRegions(_zone_)
                                                setData({ ...data, zone: _zone_, _regions: _resp, region: null })
                                            }}
                                            value={data.zone}
                                        >
                                            <option value={''}>Select Zone</option>
                                            {
                                                data._zones.map((_zone, _index) => {
                                                    return (
                                                        <option value={_zone.id} key={_zone.name}>{_zone.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='region'>region</label>
                                        <select id='region' name='region' className='form-control'
                                            onChange={e => setData({ ...data, region: e.target.value })}
                                            value={data.region}
                                        >
                                            <option value={null}>Select Region</option>
                                            {
                                                data._regions.map((_region, _index) => {
                                                    return (
                                                        <option value={_region.id} key={_region.name}>{_region.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='physical_address'>physical address</label>
                                        <input type={'text'} className='form-control' name='physical_address' id='physical_address'
                                            value={data.physical_address}
                                            onChange={e => setData({ ...data, physical_address: e.target.value })} />
                                    </div>
                                </div>
                                <div className='row justify-content-end mt-3'>
                                    <div className='col-md-6 col-sm-10 offset-md 0 offset-sm-1'>
                                        <input type={'submit'} className='form-control btn-success' id='submit' />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </>
        )
    } else {
        return <Restricted />
    }
}