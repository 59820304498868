import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { APP_TITLE, getStaff } from '../../api/env';
import { request } from '../../api/request';
import Restricted from '../static/restricted';
import Loading from '../utilities/Loading';

export default function Installers(props) {
    document.title = `${APP_TITLE}Installers`
    const _location = useLocation()
    let _search = new URLSearchParams(_location.search);
    _search = _search.get('status')
    let [data, setData] = useState({
        user: props.user,
        loading: true,
        installers: [],
        permission: false
    })

    const checkPermission = () => {
        const _permission = [1, 2]
        if (data.user) {
            if (_permission.includes(data.user.designation)) return true;
        }
        return false
    }

    const getInstallers = async () => {
        const response = await request('installers/?limit=100')
        const permission = checkPermission()
        if (response.status === 200) setData({ ...data, loading: false, installers: response.payload.results, permission })
        else setData({ ...data, loading: false })
    }

    useEffect(() => {
        getInstallers()
    }, [])

    return (
        <div className='row'>
            {
                data.loading && <Loading />
            }
            {
                !data.loading && data.permission && <>
                    <div className='col-10 page-header ml-3 mb-4'>
                        <h2>Installers</h2>
                    </div>
                    <div className='col-12 table-responsive'>
                        <table className='table table-hover table-striped'>
                            <thead >
                                <tr>
                                    <th>Installer Name</th>
                                    <th>Phone Number</th>
                                    <th>Zone</th>
                                    <th>Region</th>
                                    <th>Physical Address</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    data.installers.length > 0 && data.installers.map((_installer, _index) => {
                                        return (
                                            <tr key={_installer.uid}>
                                                <td>{_installer.name}</td>
                                                <td>{_installer.phone_number}</td>
                                                <td>{_installer.zone}</td>
                                                <td>{_installer.region}</td>
                                                <td>{_installer.physical_address}</td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    data.installers.length == 0 &&
                                    <tr>
                                        <td colSpan={4} style={{ textAlign: 'center' }}>
                                            No installers yet
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            }
            {
                !data.loading && !data.permission && <Restricted />
            }
        </div>
    )
}