import React from "react";

export default function Http404() {

  return (
    <div className="row align-items-center justify-content-center" style={{
      width: "100%",
      marginTop: "100px",
      fontSize: "22px",
      textAlign: "center"
    }}>
      Sorry there <br />
      This page is not available.
    </div>
  );
}