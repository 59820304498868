import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_TITLE, getStaff } from '../../api/env';
import { request } from '../../api/request';
import Restricted from '../static/restricted';
import Loading from '../utilities/Loading';

export default function NewAgent(props) {
    document.title = `${APP_TITLE}Home`

    let [data, setData] = useState({
        user: props.user,
        first_name: '',
        last_name: '',
        email_address: '',
        designation: '',
        office: '',
        created_by: '',
        phone_number: '',
        staff_sales_point: '',
        permission: true,
        roles: [],
        loading: false,
    })

    const _history = useHistory();

    const checkPermission = () => {
        const _permission = [1, 3]
        if (data.user) {
            if (_permission.includes(data.user.designation)) return true;
        }
        return false
    }

    const getRoles = async () => {
        const response = await request('roles/')
        const _perm = checkPermission()
        if (response.status === 200) setData({ ...data, loading: false, roles: response.payload.results, permission: _perm })
        else setData({ ...data, loading: false, permission: _perm })
    }

    const submitForm = async () => {
        setData({ ...data, loading: true })
        const payload = {
            "first_name": data.first_name,
            "last_name": data.last_name,
            "password": "123456",
            "email_address": data.email_address,
            "phone_number": data.phone_number,
            "designation": data.designation,
            "office": data.office,
            "staff_sales_point": data.staff_sales_point,
            "created_by": data.user.uid
        }
        const response = await request('staff/', payload, 'POST')
        if (response.status === 201) {
            _history.push('/agents')
            setData({ ...data, loading: false })
        } else {
            setData({ ...data, loading: false })
        }
    }

    useEffect(() => {
        getRoles()
    }, [])

    if (data.permission) {
        return (
            <>
                {
                    data.loading && <Loading />
                }
                {
                    !data.loading && <div className='row'>
                        <div className='col-10 page-header ml-3 mb-4'>
                            <h2>Add a new agent</h2>
                        </div>

                        <div className='col-12'>
                            <form className='form' onSubmit={e => { e.preventDefault(); submitForm(); }}>
                                <div className='row'>
                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='first_name'>First Name</label>
                                        <input type={'text'} className='form-control' name='first_name' id='first_name'
                                            value={data.first_name} required={true}
                                            onChange={e => setData({ ...data, first_name: e.target.value })} />
                                    </div>
                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='last_name'>last Name</label>
                                        <input type={'text'} className='form-control' name='last_name' id='last_name'
                                            value={data.last_name} required={true}
                                            onChange={e => setData({ ...data, last_name: e.target.value })} />
                                    </div>
                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='email_address'>Email Address</label>
                                        <input type={'email'} className='form-control' name='email_address' id='email_address'
                                            value={data.email_address} required={true}
                                            onChange={e => setData({ ...data, email_address: e.target.value })} />
                                    </div>
                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='phone_number'>phone number</label>
                                        <input type={'text'} className='form-control' name='phone_number' id='phone_number'
                                            value={data.phone_number} maxLength={10}
                                            onChange={e => setData({ ...data, phone_number: e.target.value })} />
                                    </div>

                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='office'>Agent's Office</label>
                                        <select id='office' name='office' className='form-control'
                                            onChange={e => setData({ ...data, office: e.target.value })}
                                            value={data.office} required={true}
                                        >
                                            <option value={''}>Select one</option>
                                            <option value={"DStv"}>DStv</option>
                                            <option value={"Step mark"}>Step Mark</option>
                                        </select>
                                    </div>

                                    {
                                        data.office === 'DStv' &&
                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='staff_sales_point'>Agent's sales point</label>
                                            <select id='staff_sales_point' name='staff_sales_point' className='form-control'
                                                onChange={e => setData({ ...data, staff_sales_point: e.target.value })}
                                                value={data.staff_sales_point}
                                            >
                                                <option value={''}>Select one</option>
                                                <option value={"Call Center"}>Call Center</option>
                                                <option value={"Over the counter (OTC)"}>Over the counter (OTC)</option>
                                                <option value={"Telesales"}>Telesales</option>
                                            </select>
                                        </div>
                                    }
                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='designation'>Designation</label>
                                        <select id='designation' name='designation' className='form-control'
                                            onChange={e => setData({ ...data, designation: e.target.value })}
                                            value={data.designation}
                                        >
                                            <option value={null}>Select Designation</option>
                                            {
                                                data.roles.map((_role, _index) => {
                                                    return (
                                                        <option value={_role.role_id} key={_role.role_id}
                                                        >{_role.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='row justify-content-end mt-3'>
                                    <div className='col-md-6 col-sm-10 offset-md 0 offset-sm-1'>
                                        <input type={'submit'} className='form-control btn-success' id='submit' />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </>
        )
    } else {
        return <Restricted />
    }
}