import React, { useState } from "react"
import Modal from 'react-modal';
import ReactDatePicker from 'react-datepicker'
import './style.css'
import { formatDate } from '../utilities/utils';
import { request } from "../../api/request";
import Loading from "../utilities/Loading";

export default function ReportFilter(props) {

    Modal.setAppElement('#root');

    let [data, setData] = useState({
        loading: false,
        startDate: null,
        endDate: null,
        sales_point: '',
        customer_type: ''
    })

    const getReport = async () => {
        const payload = {
            "start": formatDate(data.startDate, true),
            "end": formatDate(data.endDate, true),
            "sales_point": data.sales_point === '' || data.sales_point === 'All' ? null : data.sales_point,
            "customer_type": data.customer_type === '' || data.customer_type === 'All' ? null : data.customer_type
        }
        const response = await request('cases/export-cases/', payload, "POST")
        if (response.status === 200) {
            window.open(response.payload.download, '_blank')
            props.onClose()
        } else {
            alert("Report extraction failed, Please try again after a while!")
        }
    }

    return (
        <Modal
            contentLabel="Verification Modal"
            isOpen={props.isOpen}
            // onAfterOpen={}
            onRequestClose={props.onClose}
            shouldCloseOnOverlayClick={false}
            style={{
                overlay: {
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    overflow: 'auto'
                },
                content: {
                    position: 'absolute',
                    top: '1em', left: '30%',
                    height: '520px',
                    width: '400px',
                    backgroundColor: '#fff',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    zIndex: 10
                }
            }}
        >
            <div className='container'>
                <div className='row'>
                    {
                        !data.loading && <div className='col-12'>
                            <form className='form'
                                onSubmit={async e => {
                                    e.preventDefault()
                                    getReport()
                                }}
                            >
                                <div className='row'>
                                    <div className='form-group col-10 offset-1'>
                                        <label htmlFor='password1'>Start Date</label>
                                        <ReactDatePicker
                                            placeholderText='Start Date'
                                            id='startDate'
                                            className='form-control'
                                            selected={data.startDate}
                                            onChange={(_date) => setData({
                                                ...data,
                                                startDate: _date
                                            })}
                                            required
                                        />
                                    </div>
                                    <div className='form-group col-10 offset-1'>
                                        <label htmlFor='password2'>End date</label>
                                        <ReactDatePicker
                                            placeholderText='Start Date'
                                            id='endDate'
                                            className='form-control'
                                            selected={data.endDate}
                                            onChange={(_date) => setData({
                                                ...data,
                                                endDate: _date
                                            })}
                                            required
                                        />
                                    </div>

                                    <div className='form-group col-10 offset-1'>
                                        <label htmlFor='sales_point'>Sales Point</label>
                                        <select id='sales_point' name='sales_point' className='form-control'
                                            onChange={e => setData({ ...data, sales_point: e.target.value })}
                                            value={data.sales_point}
                                        >
                                            <option value={''}>All</option>
                                            <option value={"Call Center"}>Call Center</option>
                                            <option value={"Over the counter (OTC)"}>Over the counter (OTC)</option>
                                            <option value={"Telesales"}>Telesales</option>
                                        </select>
                                    </div>

                                    <div className='form-group col-10 offset-1'>
                                        <label htmlFor='customer_type'>Customer type</label>
                                        <select id='customer_type' name='customer_type' className='form-control'
                                            onChange={e => setData({ ...data, customer_type: e.target.value })}
                                            value={data.customer_type} required={true}
                                        >
                                            <option value={null}>All</option>
                                            <option value={"existing customer"}>Existing customer</option>
                                            <option value={"new customer"}>New customer</option>
                                        </select>
                                    </div>

                                    <div className='col-10 offset-1 mt-3'>
                                        <input type={'submit'} className='form-control btn-success'
                                            id='submit' value={"Export Report"} disabled={data.loading} />
                                    </div>
                                    <div className='col-10 offset-1 mt-4'>
                                        <div className="div-btn"
                                            onClick={() => {
                                                props.onClose()
                                            }}>Cancel</div>
                                    </div>
                                    <div className='col-10 offset-1 mt-3' style={{ color: 'red', textAlign: 'center' }}>
                                        {
                                            data.errorMessage && data.errorMessage
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    }
                    {
                        data.loading && <Loading height={400} />
                    }
                </div>
            </div>
        </Modal>
    );
};