// export const BASE_API_URL = "http://localhost:8000/v1/"
// export const BASE_MEDIA_URL = "http://localhost:8000/media/"
// export const FORGOT_PASSWORD_URL = "http://localhost:8000/forgot-password/"
export const BASE_API_URL = "https://app.stepmark.co.tz/v1/"
export const BASE_MEDIA_URL = "https://app.stepmark.co.tz/media/"
export const FORGOT_PASSWORD_URL = "https://app.stepmark.co.tz/forgot-password/"

export const formatNumber = (str, separator = ',') => {
    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

const KEY = 'ukD3mx83LJduiVTF37AafX6C'

export const DESIGNATIONS = [
    "None",
    "Stepmark Admin",
    "Stepmark Agent",
    "DStv Admin",
    "DStv Agent"
]
export const APP_TITLE = 'Stepmark/MC | '

export const getStaff = () => {
    let _staff = sessionStorage.getItem(KEY)
    if (_staff) _staff = JSON.parse(_staff)
    return _staff
}

export const setStaff = _staff => {
    sessionStorage.setItem(KEY, JSON.stringify(_staff))
}
