import React, { useState } from 'react';
import { FORGOT_PASSWORD_URL, setStaff } from '../../api/env';
import { request } from '../../api/request';

export default function Login(props) {
    let [loginData, setLoginData] = useState({
        username: '',
        password: '',
        requesting: false,
        errorMessage: null
    })

    const login = async () => {
        const payload = {
            "username": loginData.username,
            "password": loginData.password,
        }
        const response = await request('staff/login/', payload, 'POST');
        if (response.status === 200) {
            setStaff(response.payload.agent)
            setLoginData({ ...loginData, requesting: false })
            props.returnUser(response.payload.agent)
        } else {
            setLoginData({ ...loginData, requesting: false, errorMessage: 'Please use the correct Username/Password' })
        }
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8 col-md-8">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="p-5" style={{
                                backgroundColor: "#93278F",
                                color: "#FFFFFF",
                                alignItems: 'center',
                                borderRadius: "15px"
                            }}>
                                {/* <img src={Logo} height={50} width={200} /> */}
                                <div className="text-center">
                                    <h1 className="h4 mb-4">Step Mark - Sign in</h1>
                                </div>
                                <form className="user">
                                    <div className="form-group">
                                        <label htmlFor='username'>Username</label>
                                        <input type="text" className="form-control form-control-user"
                                            value={loginData.username}
                                            onChange={e => setLoginData({ ...loginData, username: e.target.value })}
                                            id="username" aria-describedby="emailHelp"
                                            placeholder="Enter your username" required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor='password'>Password</label>
                                        <input type="password" className="form-control form-control-user"
                                            value={loginData.password}
                                            onChange={e => setLoginData({ ...loginData, password: e.target.value })}
                                            id="password" placeholder="Password" required />
                                    </div>
                                    <button className="btn btn-primary btn-user btn-block mt-4"
                                        disabled={loginData.requesting}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            login();
                                        }}
                                    >
                                        Login
                                    </button>
                                    {
                                        loginData.errorMessage && <p className='error-message'>{loginData.errorMessage}</p>
                                    }

                                </form>
                                <hr />
                                <button className="btn btn-info btn-user btn-block mt-4"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        window.location.href = FORGOT_PASSWORD_URL
                                    }}
                                >
                                    <div className='forgot-password-link'>
                                        If you forgot Password, click here to reset!
                                    </div>
                                </button>
                                <div style={{ height: '20px' }}></div>
                                <div className="text-center">
                                    STEP MARK &copy; {new Date().getFullYear()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}