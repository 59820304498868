import * as types from '../types'

const initialstate = {
    user: null
}

export default function userReducer(state = initialstate, action) {
    switch (action) {
        case types.GET_USER:
            return { ...state, user: action.payload }
        default:
            return state
    }
}