import axios from "axios";
import { BASE_API_URL } from "./env";

export async function request(url, payload, method, fullUrl = false) {
    let response;
    try {
        response = await axios({
            method: method,
            url: fullUrl ? url : `${BASE_API_URL}${url}`,
            data: payload,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        });
        // console.log(response.request.data)
        return {
            status: response.status,
            message: "Successfull",
            payload: response.data
        };
    } catch (error) {
        return {
            status: 400,
            message: "failed: " + error.toString(),
            payload: null
        };
    }
}