import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_TITLE, getStaff } from '../../api/env';
import { request } from '../../api/request';
import Restricted from '../static/restricted';
import Loading from '../utilities/Loading';
import { formatPhoneNumber } from '../utilities/utils';


export default function NewCase(props) {
    document.title = `${APP_TITLE}New case`

    let [data, setData] = useState({
        user: props.user,
        loading: true,
        // form
        customer_type: '',
        case_type: '',
        name: '',
        phone_number: '',
        alt_phone_number: '',
        smart_card_number: '',
        description: '',
        zone: '',
        region: '',
        physical_address: '',
        created_by: '',
        customer_nature: '',
        // data
        _zones: [],
        _regions: [],
    })

    const _history = useHistory();

    const checkPermission = () => {
        const _permission = [3, 4]
        if (data.user) {
            if (_permission.includes(data.user.designation)) return true;
        }
        return false
    }

    const getZones = async () => {
        const response = await request('zones/')
        const _perm = checkPermission()
        if (response.status === 200) setData({ ...data, loading: false, _zones: response.payload.results, permission: _perm })
        else setData({ ...data, loading: false, permission: _perm })
    }

    const getRegions = async (z) => {
        const response = await request(`regions/?zone=${z}`)
        if (response.status === 200) return response.payload.results
        else return []
    }

    const submitForm = async () => {
        if (data.phone_number.length < 10) {
            alert('The phone number must have no less than 10 digits \r\nEg. 0777111111')
            return
        }
        if (data.smart_card_number.length < 10 && data.customer_type == 'existing customer') {
            alert('The smart card number must have no less than 10 digits \r\nEg. 7777777777')
            return
        }
        setData({ ...data, loading: true })
        const payload = {
            "customer_type": data.customer_type,
            "customer_nature": data.customer_nature,
            "case_type": data.case_type,
            "customer_name": data.name,
            "phone_number": formatPhoneNumber(data.phone_number),
            "alt_phone_number": formatPhoneNumber(data.alt_phone_number),
            "smart_card_number": data.smart_card_number,
            "description": data.description,
            "zone": data.zone,
            "region": data.region,
            "physical_address": data.physical_address,
            "created_by": data.user.uid,
        }
        const response = await request('cases/', payload, 'POST')
        // console.log(response);
        if (response.status === 201) {
            _history.push('/')
            setData({ ...data, loading: false })
        } else if (response.status === 200) {
            setData({ ...data, loading: false })
            alert(`The customer case already exists!\r\nname: ${response.payload.customer_name}\r\n` +
                `Phone: ${response.payload.phone_number}\r\Case #: ${response.payload.case_number}`)
        } else {
            setData({ ...data, loading: false })
        }
    }

    useEffect(() => {
        getZones()
    }, [])

    if (data.loading) {
        return <Loading />
    } else {
        if (data.permission) {
            return (
                <div className='row'>
                    <div className='col-12 page-header mb-4'>
                        <h2>New Customer Case</h2>
                    </div>

                    <div className='col-12'>
                        <form className='form' onSubmit={e => { e.preventDefault(); submitForm(); }}>
                            <div className='row'>
                                <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                    <label htmlFor='customer_type'>Customer type</label>
                                    <select id='customer_type' name='customer_type' className='form-control'
                                        onChange={e => setData({ ...data, customer_type: e.target.value })}
                                        value={data.customer_type} required={true}
                                    >
                                        <option value={null}>---</option>
                                        <option value={"existing customer"}>Existing customer</option>
                                        <option value={"new customer"}>New customer</option>
                                    </select>
                                </div>

                                <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                    <label htmlFor='customer_nature'>Nature of the customer</label>
                                    <select id='customer_nature' name='customer_nature' className='form-control'
                                        onChange={e => setData({ ...data, customer_nature: e.target.value })}
                                        value={data.customer_nature} required={true}
                                    >
                                        <option value={null}>Select nature of the customer</option>
                                        {
                                            data.customer_type === 'new customer' && <>
                                                <option value={"Residential"}>Residential</option>
                                                <option value={"Corporate"}>Corporate</option>
                                                <option value={"DStv Business"}>DStv Business</option>
                                            </>
                                        }
                                        {
                                            data.customer_type === 'existing customer' && <>
                                                <option value={"Residential"}>Residential</option>
                                                <option value={"DStv Business"}>DStv Business</option>
                                                <option value={"DStvi"}>DStvi</option>
                                            </>
                                        }
                                    </select>
                                </div>
                                <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                    <label htmlFor='name'>customer name</label>
                                    <input type={'text'} className='form-control' name='name' id='name'
                                        value={data.name} required={true}
                                        onChange={e => setData({ ...data, name: e.target.value })} />
                                </div>
                                <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                    <label htmlFor='phone_number'>phone number</label>
                                    <input type={"number"} className='form-control' name='phone_number' id='phone_number'
                                        value={data.phone_number} maxLength={10} required={true}
                                        onChange={e => setData({ ...data, phone_number: e.target.value })}
                                        placeholder={'0777111111'} />
                                </div>
                                <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                    <label htmlFor='alt_phone_number'>alternative phone number</label>
                                    <input type={"number"} className='form-control' name='alt_phone_number' id='alt_phone_number'
                                        value={data.alt_phone_number} maxLength={10} required={false}
                                        onChange={e => setData({ ...data, alt_phone_number: e.target.value })}
                                        placeholder={'0777000000'} />
                                </div>
                                {
                                    data.customer_type === 'existing customer' && <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='case_type'>Case type</label>
                                        <select id='case_type' name='case_type' className='form-control'
                                            onChange={e => setData({ ...data, case_type: e.target.value })}
                                            value={data.case_type}
                                        >
                                            <option value={null}>Customer case</option>
                                            <option value={"E48"}>E48</option>
                                            <option value={"E143"}>E143</option>
                                            <option value={"Others"}>Others (Please add description below)</option>
                                        </select>
                                    </div>
                                }
                                {
                                    data.customer_type === 'existing customer' && <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='smart_card_number'>smart card number</label>
                                        <input type={'number'} className='form-control' name='smart_card_number' id='smart_card_number'
                                            value={data.smart_card_number} maxLength={10}
                                            onChange={e => setData({ ...data, smart_card_number: e.target.value })} />
                                    </div>
                                }

                                <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                    <label htmlFor='zone'>zone</label>
                                    <select id='zone' name='zone' className='form-control' required={true}
                                        onChange={async e => {
                                            const _zone_ = e.target.value
                                            const _resp = await getRegions(_zone_)
                                            setData({ ...data, zone: _zone_, _regions: _resp, region: '' })
                                        }}
                                        value={data.zone}
                                    >
                                        <option value={''}>Select Zone</option>
                                        {
                                            data._zones.map((_zone, _index) => {
                                                return (
                                                    <option value={_zone.id} key={_zone.name}>{_zone.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>

                                <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                    <label htmlFor='region'>region</label>
                                    <select id='region' name='region' className='form-control'
                                        onChange={e => setData({ ...data, region: e.target.value })}
                                        value={data.region} required={true}
                                    >
                                        <option value={null}>Select Region</option>
                                        {
                                            data._regions.map((_region, _index) => {
                                                return (
                                                    <option value={_region.id} key={_region.name}>{_region.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>

                                <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                    <label htmlFor='physical_address'>physical address</label>
                                    <input type={'text'} className='form-control' name='physical_address' id='physical_address'
                                        value={data.physical_address} required={true}
                                        onChange={e => setData({ ...data, physical_address: e.target.value })} />
                                </div>

                                <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                    <label htmlFor='description'>description</label>
                                    <textarea className='form-control' name='description'
                                        value={data.description} id='description' cols={4}
                                        onChange={e => setData({ ...data, description: e.target.value })} />
                                </div>

                            </div>

                            <div className='row justify-content-end mt-3'>
                                <div className='col-md-6 col-sm-10 offset-md 0 offset-sm-1'>
                                    <input type={'submit'} className='form-control btn-success' id='submit' />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )
        } else {
            return <Restricted />
        }
    }
}