import React from "react";

export default function Restricted(props) {

    return (
        <div className="row align-items-center justify-content-center" style={{
            width: "100%",
            marginTop: "100px",
            fontSize: "22px",
            textAlign: "center"
        }}>
            {
                !props.info && <>
                    Sorry there <br />
                    You have no permission to view this page.<br />
                    Please talk to your administrator
                </>
            }
            {
                props.info && <p>{props.info}</p>
            }
        </div>
    );
}