
export const formatPhoneNumber = (phone_number, code = "255") => {
	let phoneNumber;
	if (phone_number.startsWith("0"))
		phoneNumber = `${phone_number.replace("0", "")}`;
	else {
		if (phone_number.startsWith("255")) phoneNumber = `${phone_number.replace('255', '')}`;
		else phoneNumber = `${phone_number}`;
	}
	return phoneNumber;
}

export const formatNumber = (price = "") => {
	return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatDate = (d, isReport = false) => {
	if (!d) return null;
	else {
		try {
			let day = d.getDate(), month = d.getMonth() + 1
			if (!isReport) {
				day += 1
			}
			if (day < 10) day = `0${day}`
			if (month < 10) month = `0${month}`
			return `${d.getFullYear()}-${month}-${day}`
		} catch (error) {
			return d
		}
	}
}

