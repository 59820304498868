import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { APP_TITLE } from '../../api/env';
import { request } from '../../api/request';
import Restricted from '../static/restricted';
import Loading from '../utilities/Loading';
import EditPassword from './edit-password';

export default function AgentDetails(props) {
    document.title = `${APP_TITLE}Home`

    let [data, setData] = useState({
        user: props.user,
        first_name: '',
        last_name: '',
        email_address: '',
        designation: '',
        office: '',
        created_by: '',
        phone_number: '',
        is_active: true,
        staff_sales_point: '',
        permission: true,
        roles: [],
        loading: true,
        modalVisible: false,
        editUser: false,
        update_password: false
    })

    const { agentId } = useParams()

    const checkPermission = () => {
        const _permission = [1, 3]
        if (data.user) {
            if (_permission.includes(data.user.designation) || data.user.uid === agentId) return true;
        }
        return false
    }

    const getRoles = async () => {
        const response = await request('roles/')
        if (response.status === 200) return response.payload.results
        else return []
    }

    const getAgent = async () => {
        const response = await request(`staff/${agentId}`)
        if (response.status === 200) {
            setData({
                ...data,
                first_name: response.payload.first_name,
                last_name: response.payload.last_name,
                email_address: response.payload.email_address,
                designation: response.payload.designation,
                office: response.payload.office,
                phone_number: response.payload.phone_number,
                staff_sales_point: response.payload.staff_sales_point,
                is_active: response.payload.is_active,
                permission: checkPermission(),
                loading: false,
                roles: await getRoles()
            })
        }
        else setData({ ...data, loading: false })
    }

    const update = async (_action = 'deactivate') => {
        let payload;
        payload = {
            "action": _action,
            first_name: data.first_name,
            last_name: data.last_name,
            email_address: data.email_address,
            designation: data.designation,
            phone_number: data.phone_number,
            update_password: data.update_password
        }
        // console.log(payload); return
        setData({ ...data, loading: true })
        const response = await request(`staff/${agentId}/update/`, payload, "POST")
        if (response.status === 200) {
            setData({
                ...data,
                first_name: response.payload.first_name,
                last_name: response.payload.last_name,
                email_address: response.payload.email_address,
                designation: response.payload.designation,
                office: response.payload.office,
                phone_number: response.payload.phone_number,
                staff_sales_point: response.payload.staff_sales_point,
                is_active: response.payload.is_active,
                loading: false,
                editUser: false,
                update_password: false
            })
            alert("The user account is updated successfully!")
        }
        else setData({ ...data, loading: false })
    }

    useEffect(() => {
        getAgent()
    }, [])

    if (data.permission) {
        return (
            <>
                {
                    data.loading && <Loading />
                }
                {
                    !data.loading && <div className='row'>
                        <div className='col-12 page-header mb-4'>
                            <div className='row justify-content-between'>
                                <div className='col-md-4 col-sm-10 offset-md-0 offset-sm-1'>
                                    <h1>Edit Staff</h1>
                                </div>
                                <div className='col-md-8 col-sm-10 offset-md-0 offset-sm-1 pull-right'>
                                    <div className='row justify-content-end' style={{ fontSize: 14 }}>
                                        {
                                            data.user.uid === agentId &&
                                            <div className='form-group col-md-4 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                                <div className='div-btn'
                                                    onClick={async () => { setData({ ...data, modalVisible: true }) }}>
                                                    Change password</div>
                                            </div>
                                        }
                                        {
                                            (data.user.designation === 1 || data.user.designation === 3) &&
                                            <div className='form-group col-md-4 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                                {
                                                    data.is_active && <div className='div-btn'
                                                        onClick={async () => update()}>Deactivate Agent</div>
                                                }
                                            </div>
                                        }
                                        {
                                            (!data.editUser && (data.user.designation === 1 || data.user.designation === 3)) &&
                                            <div className='form-group col-md-4 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                                {
                                                    data.is_active && <div className='div-btn'
                                                        onClick={async () => setData({ ...data, editUser: true })}>Edit User</div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-12'>
                            <form className='form' onSubmit={e => { e.preventDefault(); }}>
                                <div className='row'>
                                    {
                                        !data.is_active &&
                                        <div className='col-11 offset-1 align-items-center' style={{
                                            color: '#93278F', fontSize: "1.5rem",
                                            textAlign: 'left', marginBottom: '1.5rem',
                                            padding: '10px'
                                        }}>
                                            This staff account is deactivated!
                                        </div>
                                    }
                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='first_name'>First Name</label>
                                        <input type={'text'} className='form-control' name='first_name' id='first_name'
                                            value={data.first_name} required={true}
                                            onChange={e => setData({ ...data, first_name: e.target.value })}
                                            readOnly={!data.editUser} />
                                    </div>
                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='last_name'>last Name</label>
                                        <input type={'text'} className='form-control' name='last_name' id='last_name'
                                            value={data.last_name} required={true}
                                            onChange={e => setData({ ...data, last_name: e.target.value })}
                                            readOnly={!data.editUser} />
                                    </div>
                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='email_address'>Email Address</label>
                                        <input type={'email'} className='form-control' name='email_address' id='email_address'
                                            value={data.email_address} required={true}
                                            onChange={e => setData({ ...data, email_address: e.target.value })}
                                            readOnly={!data.editUser} />
                                    </div>
                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='phone_number'>phone number</label>
                                        <input type={'text'} className='form-control' name='phone_number' id='phone_number'
                                            value={data.phone_number} maxLength={10}
                                            onChange={e => setData({ ...data, phone_number: e.target.value })}
                                            readOnly={!data.editUser} />
                                    </div>

                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='office'>Agent's Office</label>
                                        <select id='office' name='office' className='form-control'
                                            disabled={true}
                                            onChange={e => setData({ ...data, office: e.target.value })}
                                            value={data.office} required={true}
                                        >
                                            <option value={''}>Select one</option>
                                            <option value={"DStv"}>DStv</option>
                                            <option value={"Step mark"}>Step Mark</option>
                                        </select>
                                    </div>

                                    <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <label htmlFor='designation'>Designation</label>
                                        <select id='designation' name='designation' className='form-control'
                                            onChange={e => setData({ ...data, designation: e.target.value })}
                                            value={data.designation} disabled={!data.editUser}
                                        >
                                            <option value={null}>Select Designation</option>
                                            {
                                                data.roles.map((_role, _index) => {
                                                    return (
                                                        <option value={_role.role_id} key={_role.role_id}
                                                        >{_role.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>

                                    {
                                        data.office === 'DStv' &&
                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='staff_sales_point'>Agent's sales point</label>
                                            <select id='staff_sales_point' name='staff_sales_point' className='form-control'
                                                onChange={e => setData({ ...data, staff_sales_point: e.target.value })}
                                                value={data.staff_sales_point}
                                                disabled={!data.editUser}
                                            >
                                                <option value={''}>Select one</option>
                                                <option value={"Call Center"}>Call Center</option>
                                                <option value={"Over the counter (OTC)"}>Over the counter (OTC)</option>
                                                <option value={"Telesales"}>Telesales</option>
                                            </select>
                                        </div>
                                    }
                                </div>
                                {
                                    data.editUser && <div className='row align-items-center mt-3'>
                                        <div className='col-1'>
                                            <input type={'checkbox'} className='form-control' name='update_password' id='update_password'
                                                value={data.update_password}
                                                onChange={() => {
                                                    setData({
                                                        ...data, update_password: !data.update_password
                                                    })
                                                }} />
                                        </div>
                                        <div className='col-10 mt-2' style={{ left: "-11px" }}>
                                            <label style={{ textTransform: 'none', fontSize: "18px" }} htmlFor='update_password'>Check this box if you want the password to be reset</label>
                                        </div>
                                    </div>
                                }
                                {
                                    data.editUser && <div className='row mt-4'>
                                        <div className='col-md-6 col-sm-10 offset-md-0 offset-sm-1 mb-3'>
                                            <button type={'button'} className='form-control btn-danger' id='cancel'
                                                onClick={(e) => { e.preventDefault(); setData({ ...data, editUser: false }) }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div className='col-md-6 col-sm-10 offset-md-0 offset-sm-1'>
                                            <button type={'submit'} className='form-control btn-success' id='submit'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    update('update')
                                                }}
                                            >
                                                Update User
                                            </button>
                                        </div>
                                    </div>
                                }
                            </form>
                        </div>
                        <EditPassword
                            isOpen={data.modalVisible}
                            onClose={() => setData({ ...data, modalVisible: false })}
                            agent={agentId}
                        />
                    </div>
                }
            </>
        )
    } else {
        return <Restricted />
    }
}