import { useState, useEffect } from 'react';
import { APP_TITLE } from '../../api/env';
import { request } from '../../api/request';

export default function Customers() {
    document.title = `${APP_TITLE}Customers`

    let [data, setData] = useState({
        loading: true,
        customers: []
    })

    const getCustomers = async () => {
        const response = await request('customers/')
        if (response.status === 200) setData({ ...data, loading: false, customers: response.payload.results })
        else setData({ ...data, loading: false })
    }

    useEffect(() => {
        getCustomers()
    }, [])

    return (
        <div className='row'>
            <div className='col-10 page-header ml-3 mb-4'>
                <h2>Customers</h2>
            </div>
            <div className='col-12 table-responsive'>
                <table className='table table-hover table-striped'>
                    <thead >
                        <tr>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Address</th>
                        </tr>
                    </thead>
                    <tbody >
                        {
                            data.customers.length > 0 && data.customers.map((_customer, _index) => {
                                return (
                                    <tr key={_index}>
                                        <td>{_customer.name}</td>
                                        <td>{_customer.phone_number}</td>
                                        <td>{_customer.location}</td>
                                    </tr>
                                )
                            })
                        }
                        {
                            data.customers.length == 0 &&
                            <tr>
                                <td colSpan={4} style={{ textAlign: 'center' }}>
                                    No customers yet
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}