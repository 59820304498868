import ReactLoading from "react-loading";

export default function Loading(props) {
  return (
    <div className="page-loader" style={{ height: props.height ? props.height : "60vh" }}>
      <ReactLoading
        type='spin'
        height={50}
        width={50}
        color={props.color ? props.color : "#93278F"} />
    </div>
  );
}