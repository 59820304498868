import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { APP_TITLE } from '../../api/env';
import { request } from '../../api/request';
import Loading from '../utilities/Loading'
import { formatDate } from '../utilities/utils';
import AddNote from './add-note';
import CloseCase from './close-case';
import Restricted from '../static/restricted';


export default function CaseDetails(props) {
    document.title = `${APP_TITLE}Case details`
    const leo = new Date()
    const { caseId } = useParams()

    let [note, setNote] = useState({
        addNote: false,
        notes: [],
        showNotes: false,
        closeCasedetails: false
    })
    let [data, setData] = useState({
        user: props.user,
        _installers: [],
        loading: true,
        // form
        case_number: '',
        customer_type: '',
        customer_nature: '',
        assigned_from: '',
        case_type: '',
        case_status: '',
        name: '',
        phone_number: '',
        alt_phone_number: '',
        smart_card_number: '',
        description: '',
        zone: '',
        region: '',
        physical_address: '',
        created_by: '',
        assigned_to: '',
        scheduleDate: '',
        installer: '',
        closure_reason: null,
        // others
        permission: false,
        installer_name: null,
        installer_phone: null,
        assigned: false,
        paid: false
    })

    const _history = useHistory();

    const getDetails = async () => {
        const response = await request(`cases/${caseId}/`)
        if (response.status === 200) return response.payload
        else return null
    }

    const getInstallers = async () => {
        const response = await request(`installers/?limit=100`)
        if (response.status === 200) return response.payload.results
        else return []
    }

    const getNotes = async () => {
        const response = await request(`case-notes/?id=${caseId}`)
        if (response.status === 200) setNote({ ...note, notes: response.payload.results })
    }
    const checkPermission = async () => {
        let _permission = [1, 2], permission = false
        const details = await getDetails();
        const _installers = await getInstallers()
        if (data.user) {
            if (_permission.includes(data.user.designation)) permission = true;
        }
        setData({
            ...data,
            permission,
            case_number: details.case_number,
            customer_type: details.customer_type,
            customer_nature: details.customer_nature,
            assigned_from: details.assigned_from,
            case_type: details.case_type,
            case_status: details.case_status,
            name: details.customer_name,
            phone_number: details.phone_number,
            alt_phone_number: details.alt_phone_number,
            smart_card_number: details.smart_card_number ? details.smart_card_number : '',
            description: details.description,
            zone: details.zone,
            region: details.region,
            physical_address: details.physical_address,
            created_by: details.created_by,
            assigned_to: details.assigned_to,
            assigned: details.assigned,
            paid: details.paid,
            closure_reason: details.closure_reason,
            scheduleDate: details.schedule_date ? Date.parse(details.schedule_date) : null,
            installer: details.installer !== '' && details.installer ? details.installer.uid : null,
            installer_name: details.installer !== '' && details.installer ? details.installer.name : null,
            installer_phone: details.installer !== '' && details.installer ? details.installer.phone_number : null,
            _installers,
            loading: false
        })
    }

    const submitForm = async () => {
        setData({ ...data, loading: true })
        if (
            data.customer_type === 'new customer' &&
            data.case_status === 'closed' &&
            (!data.smart_card_number || data.smart_card_number === '')
        ) {
            alert("Please use a smart card number to close a case");
            setData({ ...data, loading: false })
            return;
        }
        const payload = {
            "staff": data.user.uid,
            "case_status": data.case_status,
            "smart_card_number": data.smart_card_number,
            "installer": data.installer,
            "schedule_date": data.scheduleDate !== '' || data.scheduleDate !== null ? formatDate(data.scheduleDate) : null,
            "paid": data.paid
        }
        // console.log(payload); return;
        const response = await request(`cases/${caseId}/edit-case/`, payload, 'POST')
        if (response.status === 200) {
            _history.push('/')
            setData({ ...data, loading: false })
        } else {
            setData({ ...data, loading: false })
        }
    }

    useEffect(() => {
        checkPermission();
        getNotes();
    }, [])

    if (data.loading) {
        return <Loading />
    } else {
        if (data.permission) {
            return (
                <div className='row'>
                    {
                        data.assigned && <>
                            <div className='col-12 page-header mb-3'>
                                <div className='row justify-content-between '>
                                    <div className='col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                        <h3>Update Customer Case</h3>
                                    </div>
                                    <div className='col-md-3 col-sm-10 col-sm-offset-1 col-md-offset-0 align-items-end'>
                                        <div className='div-btn'
                                            onClick={() => { }}
                                        >
                                            {
                                                data.customer_type === 'existing customer' && <>Change to new</>
                                            }
                                            {
                                                data.customer_type === 'new customer' && <>Change to existing</>
                                            }
                                        </div>
                                    </div>

                                    {
                                        data.case_status !== 'closed' &&
                                        <div className='col-md-2 col-sm-10 col-sm-offset-1 col-md-offset-0 align-items-end'>
                                            <div className='div-btn'
                                                onClick={() => setNote({ ...note, closeCasedetails: true })}
                                            >
                                                Close case
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            {
                                (data.case_status === 'closed') &&
                                <div className='col-12'>
                                    <div className='row mb-2'>
                                        <div className='col-10 offset-1 align-items-center' style={{
                                            color: '#93278F',
                                            textAlign: 'center',
                                            fontSize: '22px'
                                        }}>
                                            {!(data.closure_reason === '' || data.closure_reason === null) && "Closed because:"}  {data.closure_reason}
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className='col-12'>
                                <form className='form' onSubmit={e => { e.preventDefault(); submitForm(); }}>
                                    <div className='row'>
                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='case_number'>case number</label>
                                            <input type={'text'} className='form-control' name='case_number' id='case_number'
                                                value={data.case_number}
                                                onChange={e => setData({ ...data, case_number: e.target.value })}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='customer_type'>Customer type</label>
                                            <input type={'text'} className='form-control' name='customer_type' id='customer_type'
                                                value={data.customer_type}
                                                onChange={e => setData({ ...data, customer_type: e.target.value })}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='name'>customer name</label>
                                            <input type={'text'} className='form-control' name='name' id='name'
                                                value={data.name}
                                                onChange={e => setData({ ...data, name: e.target.value })}
                                                readOnly={true}
                                            />
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='phone_number'>phone number</label>
                                            <input type={"text"} className='form-control' name='phone_number' id='phone_number'
                                                value={data.phone_number}
                                                onChange={e => setData({ ...data, phone_number: e.target.value })}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='alt_phone_number'>alternative phone number</label>
                                            <input type={"text"} className='form-control' name='alt_phone_number' id='alt_phone_number'
                                                value={data.alt_phone_number}
                                                onChange={e => setData({ ...data, alt_phone_number: e.target.value })}
                                                readOnly={true}
                                            />
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='customer_nature'>Customer's business Nature</label>
                                            <input type={"text"} className='form-control' name='customer_nature' id='customer_nature'
                                                value={data.customer_nature}
                                                onChange={e => setData({ ...data, customer_nature: e.target.value })}
                                                readOnly={true}
                                            />
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='assigned_from'>Assigned from</label>
                                            <input type={"text"} className='form-control' name='assigned_from' id='assigned_from'
                                                value={data.assigned_from}
                                                onChange={e => setData({ ...data, assigned_from: e.target.value })}
                                                readOnly={true}
                                            />
                                        </div>


                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='case_type'>case type</label>
                                            <input type={'text'} className='form-control' name='case_type' id='case_type'
                                                value={data.case_type}
                                                onChange={e => setData({ ...data, case_type: e.target.value })}
                                                readOnly={true}
                                            />
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='region'>region</label>
                                            <input type={'text'} className='form-control' name='region'
                                                value={data.region} id='region'
                                                readOnly={true}
                                                onChange={e => setData({ ...data, region: e.target.value })}
                                            />
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='physical_address'>physical address</label>
                                            <input type={'text'} className='form-control' name='physical_address'
                                                value={data.physical_address} id='physical_address'
                                                readOnly={true}
                                                onChange={e => setData({ ...data, physical_address: e.target.value })}
                                            />
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='created_by'>created by</label>
                                            <input type={'text'} className='form-control' name='created_by'
                                                value={data.created_by} id='created_by'
                                                readOnly={true}
                                                onChange={e => setData({ ...data, created_by: e.target.value })}
                                            />
                                        </div>
                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='assigned_to'>Assigned to</label>
                                            <input type={'text'} className='form-control' name='assigned_to'
                                                value={data.assigned_to} id='assigned_to'
                                                style={{
                                                    width: '100%'
                                                }}
                                                readOnly={true}
                                                onChange={e => setData({ ...data, assigned_to: e.target.value })}
                                            />
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <div className='row justify-content-between' style={{ paddingTop: "1em" }}>
                                                <div className='col-4'><label>Customer Notes:</label></div>
                                                <div className='form-group col-4 align-items-center'>
                                                    <button className='btn btn-info' style={{ fontSize: '15px' }}
                                                        onClick={(e) => { setNote({ ...note, addNote: true }); e.preventDefault() }}
                                                    >Add Note</button></div>
                                                <div className='col-4 form-group align-items-center'>
                                                    <button className='btn btn-primary' style={{ fontSize: '15px' }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            const notes = document.getElementById('case-notes')
                                                            if (!notes) return;
                                                            else {
                                                                if (notes.style.display === 'none') setNote({ ...note, showNotes: true })
                                                                else setNote({ ...note, showNotes: false })
                                                            }
                                                        }}
                                                    >
                                                        {!note.showNotes ? 'Show Notes' : 'Hide Notes'}
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                note.notes.length > 0 &&
                                                <div className='row'
                                                    style={{ display: note.showNotes ? 'flex' : 'none', paddingTop: "1rem" }} id="case-notes">
                                                    <div className='col-12'>
                                                        <table className='table table-hover table-bordered'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Time</th>
                                                                    <th>Agent</th>
                                                                    <th>Comment</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    note.notes.map((_note, _index) => {
                                                                        return (
                                                                            <tr key={`step${_note.uid}`}>
                                                                                <td>{_note.created_at}</td>
                                                                                <td>{_note.agent}</td>
                                                                                <td>{_note.note}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='description'>description</label>
                                            <textarea className='form-control' name='description' id='description'
                                                value={data.description}
                                                onChange={e => setData({ ...data, description: e.target.value })}
                                                readOnly={true}
                                            />
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='smart_card_number'>smart card number</label>
                                            <input type={'text'} className='form-control' name='smart_card_number'
                                                value={data.smart_card_number} id='smart_card_number'
                                                onChange={e => setData({ ...data, smart_card_number: e.target.value })}
                                            />
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='installer'>Installer</label>
                                            <select id='installer' name='installer'
                                                className='form-control'
                                                style={{ textTransform: 'capitalize' }}
                                                onChange={e => setData({ ...data, installer: e.target.value })}
                                                value={data.installer}
                                            >
                                                <option value={null}>Select Installer</option>
                                                {
                                                    data._installers.map((_installer, _index) => {
                                                        return (
                                                            <option key={_installer.uid} value={_installer.uid}>{_installer.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'></div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='scheduleDate'>Schedule Date</label>
                                            <ReactDatePicker
                                                id='scheduleDate'
                                                className='form-control'
                                                selected={data.scheduleDate}
                                                onChange={(_date) => {
                                                    setData({
                                                        ...data,
                                                        scheduleDate: _date
                                                    })
                                                }}
                                            // minDate={leo}
                                            />
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0 mt-3  '>
                                            <div className='row align-items-center mt-3'>
                                                <div className='col-1'>
                                                    <input type={'checkbox'} className='form-control' name='paid' id='paid'
                                                        value={data.paid}
                                                        checked={data.paid}
                                                        onChange={() => {
                                                            setData({
                                                                ...data, paid: !data.paid
                                                            })
                                                        }} />
                                                </div>
                                                <div className='col-10 mt-2' style={{ left: "-11px" }}>
                                                    <label style={{ textTransform: 'none', fontSize: "18px" }} htmlFor='paid'>Check this box if the case is paid for</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='form-group col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0'>
                                            <label htmlFor='case_status'>Case status</label>
                                            <select id='case_status' name='case_status'
                                                className='form-control'
                                                style={{ textTransform: 'capitalize' }}
                                                onChange={e => {
                                                    setData({ ...data, case_status: e.target.value })
                                                    if (e.target.value === 'scheduled cases') document.getElementById('scheduleDate').focus()
                                                }}
                                                value={data.case_status}
                                            >
                                                <option value={null}>change case status</option>
                                                <option value={"in progress"}>in progress</option>
                                                <option value={"customer not available"}>customer not available</option>
                                                <option value={"not picking up"}>not picking up</option>
                                                <option value={"number busy"}>number busy</option>
                                                <option value={"hung up the call"}>hung up the call</option>
                                                <option value={"scheduled cases"}>Shedule case</option>
                                                <option value={"closed"}>closed</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='row justify-content-end mt-3'>
                                        <div className='col-md-6 col-sm-10 offset-md 0 offset-sm-1'>
                                            <input type={'submit'} className='form-control btn-success' id='submit' />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </>
                    }
                    {
                        !data.assigned && <Restricted info="This case has not been assigned yet!" />
                    }
                    <AddNote
                        isOpen={note.addNote}
                        onClose={(newNote) => setNote({
                            ...note,
                            addNote: false,
                            notes: newNote ? [newNote, ...note.notes] : note.notes
                        })}
                        agent={data.user.uid}
                        case={caseId}
                    />
                    <CloseCase
                        isOpen={note.closeCasedetails}
                        onClose={() => {
                            setNote({
                                ...note,
                                closeCasedetails: false
                            })
                            _history.push('/')
                        }}
                        agent={data.user.uid}
                        case={caseId}
                    />
                </div>
            )
        } else {
            return (
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-md-12 col-sm-10 page-header mb-4'>
                                <h2>Case details</h2>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-sm-10  offset-sm-1 offset-md-0'>
                                <table className='table table-responsive table-bordered'>
                                    <tbody>
                                        <tr>
                                            <td>Case status</td>
                                            <td style={{ textTransform: 'capitalize' }}>{data.case_status}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer Name</td>
                                            <td>{data.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer phone number</td>
                                            <td>{data.phone_number} {data.alt_phone_number ? `or ${data.alt_phone_number}` : ''}</td>
                                        </tr>
                                        <tr>
                                            <td>Customer business type</td>
                                            <td>{data.customer_nature}</td>
                                        </tr>
                                        <tr>
                                            <td>Assigned from</td>
                                            <td>{data.assigned_from}</td>
                                        </tr>
                                        <tr>
                                            <td>Stepmark Agent</td>
                                            <td>{data.assigned_to}</td>
                                        </tr>
                                        <tr>
                                            <td>DStv Agent</td>
                                            <td>{data.created_by}</td>
                                        </tr>
                                        <tr>
                                            <td>Smart card Number</td>
                                            <td>{data.smart_card_number}</td>
                                        </tr>
                                        <tr>
                                            <td>Installer</td>
                                            <td>{(data.installer_name && data.installer_phone) ? `${data.installer_name} - ${data.installer_phone}` : "Not assigned"} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-md-6 col-sm-10 offset-sm-1 offset-md-0'>
                                <table className='table table-hover table-bordered'>
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Agent</th>
                                            <th>Comment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            note.notes.map((_note, _index) => {
                                                return (
                                                    <tr key={`dstv${_note.uid}`}>
                                                        <td>{_note.created_at}</td>
                                                        <td>{_note.agent}</td>
                                                        <td>{_note.note}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}