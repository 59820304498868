import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { APP_TITLE, DESIGNATIONS } from '../../api/env';
import { request } from '../../api/request';
import Loading from '../utilities/Loading';

export default function Agents() {
    document.title = `${APP_TITLE}Agents`

    let [data, setData] = useState({
        loading: true,
        agents: []
    })

    const getAgents = async () => {
        setData({ ...data, loading: true })
        const response = await request('staff/')
        if (response.status === 200) setData({ ...data, loading: false, agents: response.payload.results })
        else setData({ ...data, loading: false })
    }

    useEffect(() => {
        getAgents()
    }, [])

    return (
        <div className='row'>
            {
                data.loading && <Loading />
            }
            {
                !data.loading && <>
                    <div className='col-12 page-header mb-4'>
                        <h2>Agents</h2>
                    </div>
                    <div className='col-12 table-responsive'>
                        <table className='table table-hover table-striped'>
                            <thead >
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Designation</th>
                                    <th>Sales point</th>
                                    <th>Email Address</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    data.agents.length > 0 && data.agents.map((_agent, _index) => {
                                        return (
                                            <tr key={_agent.uid}>
                                                <td>
                                                    <Link className='td-link' to={`/agents/${_agent.uid}`}>
                                                        {_agent.first_name}
                                                    </Link>
                                                </td>
                                                <td>{_agent.last_name}</td>
                                                <td>{DESIGNATIONS[_agent.designation]}</td>
                                                <td>{_agent.staff_sales_point}</td>
                                                <td>{_agent.email_address}</td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    data.agents.length === 0 && data.agents.map((_agent, _index) => {
                                        return (
                                            <tr>
                                                <td colSpan={4} style={{ textAlign: 'center' }}>
                                                    No agents registered
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </div>
    )
}